declare var require: any;
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert';
import { EmailValidatorService } from '../../services/EmailValidator/email-validator.service';
import { HttpClient } from '@angular/common/http';
const adjectiver = require('@overrideveloper/adjectiver');

const config = require('../../dictionary'),
  nouns = config.nouns.all, adjectives = config.adjectives,
  URL = config.url;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public name = '';
  public adjectives = [];
  public names = ['Wisdom', 'Stephen', 'Jordan'];
  public url = '';
  public emailValidity = false;
  public beta_tester = { email: '', name: '', status: 'unverified', recaptcha: '' };
  public year;

  constructor(public route: ActivatedRoute, public router: Router, public emailValidator: EmailValidatorService,
    public http: HttpClient) { }

  ngOnInit() {
    const date = new Date();
    this.year = date.getFullYear();
    this.preparations();
    this.campaign();
  }

  preparations() {
    this.name = this.route.snapshot.paramMap.get('name');
    this.name = this.name.charAt(0).toUpperCase() + this.name.slice(1);
    this.url = window.location.href;
    this.url = this.url.substr(0, this.url.lastIndexOf('/') + 1);
  }

  campaign() {
    switch (this.name) {
      case 'Random':
        this.name = this.names[Math.floor(Math.random() * this.names.length)];
        this.adjectives = adjectiver.getAdjectives(3, this.name);
        break;

      default:
        this.name = this.getName(false, this.name);
        this.adjectives = adjectiver.getAdjectives(3, this.name);
        break;
    }
  }

  getName(randomize = true, noun = '') {
    let name;
    if (randomize) {
      name = nouns[Math.floor(Math.random() * nouns.length)];
    } else {
      name = noun;
    }
    return name;
  }

  share(e) {
    e.preventDefault();
    swal({
      title: 'Share with a friend',
      content: {
        element: 'input',
        attributes: {
          placeholder: `Enter friend's name`,
          type: 'text'
        }
      },
      buttons: [true, true]
    }).then((input) => {
      if (input) {
        if (input === false) {
          return false;
        }
        if (input === '') {
          swal({
            text: 'You have to input a name!',
            icon: 'error'
          });
          return false;
        }
        if (input !== '') {
          const url = `${this.url}${input}`;
          swal({
            title: `Share this with ${input}`,
            text: url,
            icon: 'success',
            buttons: [true, 'Copy']
          }).then((selected) => {
            if (selected) {
              const copy = this.copyToClipboard(url);
              if (copy === true) {
                swal({
                  text: 'Copied to clipboard',
                  icon: 'success',
                  timer: 5000,
                  buttons: [false, false]
                });
              }
            }
          });
        }
      } else { }
    });
  }

  copyToClipboard = str => {
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =
      document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }
    return true;
  }

  validateEmail(email) {
    this.emailValidity = this.emailValidator.isValid(email);
  }

  reset() {
    this.beta_tester = { email: '', name: '', status: 'unverified', recaptcha: '' };
  }

  submit() {
    if (this.beta_tester.recaptcha === '') {
      const form = new FormData();
      form.append('name', this.beta_tester.email);
      form.append('status', this.beta_tester.status);
      form.append('email', this.beta_tester.email);

      this.http.post(`${URL}/beta_tester/create`, form).subscribe((data: any) => {
        if (data.code === 200) {
          this.reset();
          swal({
            title: 'Okay!',
            text: `Thanks! We'll reach out to you when we launch!`,
            icon: 'success',
            timer: 5000,
            buttons: [false, false]
          });
        } else {
          swal({
            title: 'Error!',
            text:  `We already have your details. We'll reach out to you when we launch!`,
            icon: 'error',
            timer: 5000,
            buttons: [false, false]
          });
        }
      }, error => {
        swal({
          title: 'Error!',
          text: error.message,
          icon: 'error',
          timer: 5000,
          buttons: [false, false]
        });
      });
    } else {
      console.log('This is a bot request');
    }
  }
}
