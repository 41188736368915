import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxCaptchaModule } from 'ngx-captcha';

import { AppComponent } from './app.component';
import { HomeComponent } from '../components/home/home.component';

import { EmailValidatorService } from '../services/EmailValidator/email-validator.service';

const routes: Routes = [
  { path: '', redirectTo: '/home/random', pathMatch: 'full' },
  { path: 'home/:name', component: HomeComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    NgxCaptchaModule.forRoot({
      reCaptcha2SiteKey: '6LdqpWwUAAAAAFRdTWSQQAvzDFzk8QOuoWeNosxk',
      invisibleCaptchaSiteKey: '6LdqpWwUAAAAAFRdTWSQQAvzDFzk8QOuoWeNosxk'
    }),
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  providers: [EmailValidatorService],
  bootstrap: [AppComponent]
})
export class AppModule { }
